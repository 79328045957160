<template>
  <div class="page-container">
    <div class="card-container">
      <div class="img">
        <img :src="forgetPasswordIcon" width="96" alt="" />
      </div>
      <div class="title1">{{ translateTitle("重置密码") }}</div>
      <div class="user">
        <img :src="userIcon" width="16" alt="" />
        <span>{{ handleAccount($route.query.account) }}</span>
      </div>
      <div class="desc">
        {{
          translateTitle(
            "为确保您的账户安全，在您更改密码后，提现、C2C出售、支付服务和金融卡等功能将被禁用24小时。"
          )
        }}
      </div>
      <div class="btn-list">
        <el-button class="btn" @click="$router.back()">{{
          translateTitle("取消")
        }}</el-button>
        <el-button class="btn" type="primary" @click="onNext">{{
          translateTitle("继续")
        }}</el-button>
      </div>
    </div>
    <Geetest
      ref="geeRef"
      :isGeet="isShowGeet"
      :isRegister="false"
      @geetParam="success"
    />
  </div>
</template>

<script>
import forgetPasswordIcon from "@/assets/img/users/forget.png";
import userIcon from "@/assets/img/users/user.png";
import Geetest from "@/components/Geetest.vue";
import { UCenterRequest } from "@/model/UCenterService";

export default {
  components: {
    Geetest,
  },
  data() {
    return {
      forgetPasswordIcon,
      userIcon,
      isShowGeet: true,
    };
  },
  methods: {
    handleAccount(account) {
      if (!account.includes("@")) {
        return account.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2");
      }
      return account.replace(/(\w{1}).*(\w{1})@(.*)/, "$1***$2@$3");
    },
    async success(params) {
      const res = await UCenterRequest.checkUniversal({
        login_name: this.$route.query.account,
        ...params,
      });
      if (res.status === 200) {
        this.userData = res.data;
        this.$router.push({
          name: "SafetyVerify",
          query: {
            ...this.$route.query,
            source: "forgetPassword",
            backRouteName: "ResetPassword",
          },
          params: {
            ...this.$route.params,
            ...res.data.forget_info,
            token: res.data.token,
            tokenForget: res.data.token_forget,
          },
        });
      }
    },
    onNext() {
      if (this.$refs.geeRef.captchaObject !== null) {
        this.$refs.geeRef.captchaObject.showCaptcha();
      } else {
        this.isShowGeet = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/common.scss";

.card-container {
  padding-top: 56px !important;
}

.img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title1 {
  color: #12161b;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 85.714% */
  margin-top: 10px;
  text-align: center;
}

.user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: 12px;
  img {
    flex-shrink: 0;
    height: 16px;
    margin-right: 3px;
    display: block;
  }

  span {
    color: var(--7-c_t01, #0d0e0e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
  }
}

.desc {
  margin-top: 28px;
  color: var(---c_t03, #8b909c);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.btn-list {
  display: flex;
  margin-top: 139px;
  .btn {
    flex: 1;
    height: 48px;
    border-radius: 6px;
    color: var(--7-c_t01, #0d0e0e);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 131.25% */
    &.el-button--default {
      background: var(--7-c_btn_bg, #eaedf4);
      border: none;
      &:hover {
        color: var(--7-c_t01, #0d0e0e);
      }
    }

    &.el-button--primary {
      background-color: #3449f5;
      border-color: #3449f5;
      color: #fff;
      margin-left: 12px;
    }
  }
}
</style>
